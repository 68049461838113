@import "~bootstrap/dist/css/bootstrap.css";

h1 {
  display: inline-block;
  vertical-align: middle;
  line-height: normal;
  font-family: 'Inconsolata', monospace;
  font-weight: bold;
}

.page-header {
  color: white;
  text-align: center;
  background-image: linear-gradient(black, #8f0000, black);
  height: 100px;
  line-height: 100px;
}

div {
  font-family: 'Roboto Mono', monospace;
  color: white;
}

.main-section {
  background-color: black;
  min-height: 900px;

  @media(max-width:650px) {
    min-height: 400px;
  }
}

.title {
  font-style: italic;
  font-weight: bold;
}

.production-button {
  border-radius: 5px;
  margin: 5px;
  background-color: #8f0000;
  border: 0px;
  color: white;

  @media(max-width: 650px) {
      font-size: 14px;
  }
}

.production-button[disabled] {
  background-color: dimgrey;
}

.breaker {
  color: red;
}

h2 {
  padding-top: 20px;
  font-size: 40px;
  font-style: italic;
}

a {
  color: white;
}

a:hover {
  text-decoration: none;
}
